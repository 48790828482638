@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentRegistro {
    --background: url("../assets/imgs/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.formRegistro {
    height: 100%;
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridRegistro {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowRegistro {
    height: auto;
    margin: auto;
    width: 100%;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.textRegistro {
    width: 100%;
}

.radioGroup {
    width: 100%;
}

.radioRegistro {
    --color-checked: #03a66a;
    --color: #03a66a;
    --border-color: #03a66a;
}

.error-msg {
    color: red;
}

@media screen and (max-width: 764px) {
    .radioGroup {
        flex-direction: column;
    }
}
