@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
  font-family: "Montserrat", sans-serif;
  color: black;
}

ion-item{
  --background: white;
  --color: black;
}

.gridValidacion {
  min-height: 100%;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}

.rowValidacion {
  height: auto;
  margin: auto;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
}

.infoValidacion span {
  text-align: justify;
}

.opc {
  border: 1px solid #03a66a;
}

.opcRow{
  height: 100%;
}

.opcCol{
  display: flex;
  align-self: stretch;
}

.opcLeft {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.opcRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.opcHead {
  padding: 8px 0;
  border-bottom: 1px solid #03a66a;
}

.opcBody {
  padding: 2.5em 3px;
}

.radioSelect {
  width: 0;
  height: 0;
  margin: 0;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  overflow: visible;
}

.itemOpt {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  width: 100%;
}

.labelOpt {
  word-wrap: break-word;
  margin: 0;
}

.headerOpt {
  padding: 5px 0;
  border-bottom: 1px solid #03a66a;
}

.bodyOpt {
  padding: 20px;
}
