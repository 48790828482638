@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
  font-family: "Montserrat", sans-serif;
  color: black;
}

.gridSeccion2 {
  min-height: 100%;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}

.rowSeccion2 {
  height: auto;
  margin: auto;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  width: 96%;
}

.infoSeccion2 span {
  text-align: justify;
}

.opcSeccion2 {
  border: 1px solid #03a66a;
  border-radius: 10px;
  margin: 10px auto;
}

.iconMore{
  cursor: pointer;
  margin: 0 6px;
  font-size: 19px;
}

.radioSelect2 {
  width: 0;
  height: 0;
  margin: 0;
  --border-width: 0;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  overflow: visible;
}

.labelOptSeccion2 {
  word-wrap: break-word;
  margin: 0;
  padding: 6px;
}

.index{
  padding: 10px 15px;
  border: 1px solid #03a66a;
  border-radius: 50px;
}

.opcSeccion2 .item-radio-checked {
  --background: #03a66a;
}

.opcSeccion2 .item-radio-checked h2 {
  color: white;
}

.imgSeccion2{
  display: block;
  max-width: 200px;
  margin: auto;
}

.close{
  position: absolute;
  right: 10px;
  top: 10px;
}

.colSecc2 .item-radio-checked{
  --background: #03a66a;
}

.item-radio-checked h2{
  color: white;
}

.item-radio-checked .headerOpt{
  border-color: white;
}

@media screen and (max-width: 764px) {
  .index{
    padding: 5px 10px;
  }
}