@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentResultados {
    --background: url("../assets/imgs/resultados/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.gridResultados {
    width: 85%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowResultados {
    height: auto;
    margin: auto;
    width: 95%;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.colText1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #6e6f73b0;
}

.colText2 {
    color: #6e6f73;
    border-top: 1px solid #6e6f73b0;
}

.h6Title {
    color: #03a66a;
    font-weight: bolder;
}

.iconResult {
    font-size: 20px;
    margin: auto 5px;
    cursor: pointer;
    margin-top: 3px;
}

.h1TopInfo {
    margin: auto;
    text-align: center;
}

.h1TopInfo b {
    color: #03a66a !important;
}

.gridModalInfo {
    background-color: white;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
}

.modal-wrapper {
    height: 100%;
}

.copyEmpty {
    text-transform: lowercase;
}

ion-modal.modal-autoheight {
    --height: 50%;
}

.icon-home {
    font-size: 28px;
    color: white !important;
    border: 2px solid #03a66a;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    background-color: #03a66a;
}

.icon-home:hover {
    background-color: #037c50;
    border: 2px solid #037c50;
}

/* ion-modal.modal-autoheight .ion-content {
    position: relative;
    display: block;
    contain: content;
}

ion-modal.modal-autoheight .ion-content .modalInfo {
    max-height: 80vh;
    overflow: auto;
} */

@media screen and (max-width: 764px) {
    .imgResult {
        max-width: 95%;
        display: block;
        margin: auto;
    }
}
