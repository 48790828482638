@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
  font-family: "Montserrat", sans-serif;
  color: black;
}

.contentHome {
  --background: url("../assets/imgs/fondo.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.gridHome {
  min-height: 100%;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.rowHome {
  height: auto;
  margin: auto;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
}

.logoCol {
  position: relative;
}

.sello {
  position: absolute;
  max-width: 250px;
  width: 14vw;
  right: 2%;
  top: 2%;
  z-index: 99;
}

.logo1 {
  max-width: 250px;
}

.logo2 {
  display: block;
  max-width: 80%;
  margin: auto;
}

.input {
  border-radius: 5px;
  border: 1px solid #03a66a;
  color: #03a66a;
  font-family: "Montserrat";
}

.native-input.sc-ion-input-md {
  color: grey;
}

.modalRepos {
  --background: url("../assets/imgs/fondo2.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.gridModal {
  background-color: white;
  min-height: 100%;
  border-radius: 10px;
}

.h1Top {
  text-align: center;
  color: #03a66a;
}

.rowModal {
  border-bottom: 1px solid #03a66a;
}
