@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentIntro {
    --background: url("../assets/imgs/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.gridIntro {
    min-height: 100%;
    width: 85%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowIntro {
    height: 90%;
    margin: auto;
    border-radius: 15px;
    background-color: white;
    padding: 10px;
}

.infoIntro span {
    text-align: justify;
}

.input {
    border-radius: 5px;
    border: 1px solid #03a66a;
    color: #03a66a;
    font-family: "Montserrat";
}

.imgIntro{
    display: block;
    max-width: 400px;
    width: 100%;
    /* margin: auto; */
}

@media screen and (max-width: 768px) {
    .imgIntro{
        display: block;
        max-width: 30%;
        width: 100%;
        max-height: 100%;
        margin: auto;
    }
    
}