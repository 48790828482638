@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentValidacion {
    --background: url("../assets/imgs/fondo2.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}