@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentTyC {
    --background: url("../assets/imgs/tyc/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: center top;
}

.gridTyC {
    min-height: 100%;
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowTyC {
    height: 90%;
    width: 100%;
    margin: 40px auto;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.colTyC{
    height: 100%;
}

.swiperTyC{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 !important;
}

.gridSwiperTyC{
    height: 100%;
}

.gridSwiperTyC ion-row{
    height: 100%;
    width: 90%;
    margin: auto;
    justify-content: space-around;
}

.gridSwiperTyC ion-row ion-col{
    text-align: justify;
}

.infoTyC {
    text-align: justify;
}

.recursos{
    max-width: 250px;
    display: block;
    margin: 20px auto;
}

@media screen and (min-width: 768px) {
    .gridSwiperTyC ion-row{
        height: 100%;
        width: 90%;
        margin: auto;
        justify-content: space-around;
    }
}