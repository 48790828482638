@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
    font-family: "Montserrat", sans-serif;
    color: black;
}

.contentInstruccion {
    --background: url("../assets/imgs/fondo2.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.gridInstruccion {
    min-height: 100%;
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.rowInstruccion {
    height: auto;
    margin: auto;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.infoInstruccion span {
    text-align: justify;
}

.opc {
    border: 1px solid #03a66a;
}

.opcHead {
    padding: 8px 0;
    border-bottom: 1px solid #03a66a;
}

.opcBody {
    padding: 2.5em 3px;
}

.itemOpt {
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}

.opcCol .item-radio-checked {
    --background: #03a66a;
}

.item-radio-checked h2 {
    color: white;
}

.item-radio-checked .headerOpt {
    border-color: white;
}

.radioSelect {
    width: 0;
    height: 0;
    margin: 0;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    overflow: visible;
}

.labelOpt {
    word-wrap: break-word;
    margin: 0;
}

.headerOpt {
    padding: 5px 0;
    border-bottom: 1px solid #03a66a;
}

.bodyOpt {
    padding: 20px;
}
