@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat.ttf") format("truetype");
}

* {
  font-family: "Montserrat", sans-serif;
  color: black;
}

.gridProblema {
  min-height: 100%;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}

.rowProblema {
  height: auto;
  margin: auto;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  width: 96%;
}

.infoProblema span {
  text-align: justify;
}

.imgProblema{
  display: block;
  max-width: 200px;
  margin: auto;
}